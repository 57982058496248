@import '../../style/abstract/variables';

@import '../../style/abstract/media';
@import '../../style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';
@import '../../style/abstract/placeholder';

:root {
    --category-price-slider-track-height: 3px;
    --category-price-slider-main-color: #{$orange50};
}

.CategoryPriceSlider {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 20px;

    .MuiSlider {
        &-root {
            width: calc(100% - 18px);
            margin-left: 8px;
            height: var(--category-price-slider-track-height);
        }

        &-track {
            height: var(--category-price-slider-track-height);
            background-color: var(--category-price-slider-main-color);
        }

        &-rail {
            height: var(--category-price-slider-track-height);
        }

        &-thumb {
            width: 16px;
            height: 16px;
            background: $white;
            border: 2px solid var(--category-price-slider-main-color);
            box-sizing: border-box;
            border-radius: 5px;
            margin-top: -7px;

            &::after {
                display: none;
            }

            &.MuiSlider-active,
            &.Mui-focusVisible,
            &:hover {
                box-shadow: none;
            }
        }
    }

    &-InputsWrapper {
        margin: 25px 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 22px;
        width: 100%;
    }

    &-Input {
        &[type='number'] {
            width: 100%;
            background-color: $white;
            padding: 11px 35px 11px 16px;
            border: solid 1.5px $grey30;
            height: 40px;
            font-size: 13px;
            line-height: 20px;
            color: $grey70;
            font-weight: 300;
            text-align: left;
            pointer-events: auto;

            &:not(:first-of-type) {
                margin-top: 0;
            }

            &:focus {
                border-color: $default-secondary-base-color;
                border-width: 1.5px;
                color: $grey70;
            }
        }
    }

    &-Button {
        align-self: flex-end;
        box-shadow: none;
        font-size: 13px;
    }
}
